/* eslint-disable no-plusplus */
/* eslint-disable no-extend-native */
import { useState } from 'react';

const useProductCards = () => {
  const moveEnu = {
    next: 1,
    previous: -1,
  };

  const NOT_FOUND = 'not found';
  const [currentProduct, setCurrentProduct] = useState('');

  const getProductCode = (productCode, increment) => {
    const dataIndexes = JSON.parse(sessionStorage.getItem('allDataIndexes') || '[]');
    // if any duplicates it should find the last duplicate
    const index = dataIndexes.map(dataIndex => dataIndex.productCode).lastIndexOf(productCode);
    if (index === -1) return NOT_FOUND;
    return dataIndexes?.[index + increment]?.productCode ?? NOT_FOUND;
  };

  const moveNext = (productCode) => {
    const nextProduct = getProductCode(productCode, moveEnu.next);
    setCurrentProduct(nextProduct);
  };

  const movePrevious = (productCode) => {
    const prevProduct = getProductCode(productCode, moveEnu.previous);
    setCurrentProduct(prevProduct);
  };

  return {
    moveNext,
    movePrevious,
    setCurrentProduct,
    currentProduct,
    NOT_FOUND,
  };
};

export default useProductCards;
