/* eslint-disable max-len */
import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';

import Toggle from '../../../components/toggle/Toggle';
import BaseViewPage from '../../baseView/BaseViewPage';
import { baseDialogWrapper as Dialog } from '../../baseView/BaseViewPageUtils';
import DetailsWidgetContainer from './detailsWidget/DetailsWidgetContainer';
import GroupedBarChartWithLegend from '../../../components/visualSummaries/groupedBarChart/GroupedBarChartWithLegend';
import CustomSpinner from '../../../components/CustomSpinner';
import ProductCard from './products/ProductCard';
import { getAggregatedBy } from '../../../components/toggle/GenderCategoryToggle';
import useTableOptions, { getColumns } from './scanDetails.utils';
import useProductCards from '../../../hooks/useProductCards';

import { SimWebContext } from '../../../context/SimWeb.provider';
import { ScanDetailsContext } from './ScanDetails.provider';
import { ScanTimerContext } from '../../../context/ScanTimer.provider';
import { CPAContext } from '../../../context/cpa/CPA.provider';

import './ScanDetailsPage.css';

const ScanDetailsPage = () => {
  const {
    locale, currencyCode, formatMessage, getMessage, isOffsiteEnabled, isOffsiteInactive,
    nikeStoreRoles, SimDateTime, storeConfig,
  } = useContext(SimWebContext);

  const {
    id, data, items, loading, error, sohLoading, setScanItems, setSOHLoading,
  } = useContext(ScanDetailsContext);

  const { isScanNotProccessing, scanProgress } = useContext(ScanTimerContext);
  const { getProducts } = useContext(CPAContext);

  const [selectedToggle, setSelectedToggle] = useState('genderAge');
  const [isOpened, setIsOpened] = useState(false);
  const [scanProductDetailData, setScanProductDetailData] = useState(null);
  const [varianceType, setVarianceType] = useState('missing');
  const [tableFilters, setTableFilters] = useState([]);

  const {
    currentProduct, setCurrentProduct, moveNext, movePrevious, NOT_FOUND,
  } = useProductCards();

  const accuracySubmissionThreshold = storeConfig?.accuracySubmissionThreshold?.value;
  const rfidEnabled = storeConfig?.rfidEnabled?.value;
  const pageTitle = 'scanReport';

  const toggles = {
    gender: {
      name: 'genderAge',
      label: getMessage('gender'),
      aggregatedBy: 'genderAge',
    },
    category: {
      name: 'retailCategory',
      label: getMessage('category'),
      aggregatedBy: 'retailCategory',
    },
  };

  useEffect(() => {
    // used to determine if something has changed. using updateProps will cause an infinite loop
    sessionStorage.setItem('displayedRowCount', 0);
    sessionStorage.setItem('page', -1);
    sessionStorage.setItem('allDataIndexes', JSON.stringify([]));
  }, []);

  useEffect(() => {
    if (currentProduct === NOT_FOUND) return;

    getProducts(currentProduct);
    const productDetails = items?.find(item => item.productCode === currentProduct);
    setScanProductDetailData(productDetails);
  }, [currentProduct, items]);

  const customSummaryContainer = useMemo(() => {
    const toggle = (
      <Toggle
        hasSeveralToggles
        toggleLabelsArray={Object.values(toggles)}
        selected={selectedToggle}
        setSelected={value => { setSelectedToggle(value); }}
        toggleByLabel
        toggleDirection="vertical"
      />
    );

    const aggregatedBy = getAggregatedBy(toggles, selectedToggle);

    return (
      <div className="scan-details-summary-container" data-testid="scan-details-summary-container">
        <DetailsWidgetContainer
          accuracySubmissionThreshold={accuracySubmissionThreshold}
          data={loading ? {} : data}
          formatMessage={formatMessage}
          getMessage={getMessage}
          isOffsiteEnabled={isOffsiteEnabled}
          isScanNotProccessing={isScanNotProccessing}
          loading={loading}
          locale={locale}
          nikeStoreRoles={nikeStoreRoles}
          scanProgress={scanProgress}
          SimDateTime={SimDateTime}
        />
        <GroupedBarChartWithLegend
          id={id}
          data={loading ? {} : data?.graphAggregations}
          loading={loading}
          error={!!error}
          aggregatedBy={aggregatedBy}
          toggle={toggle}
          varianceType={varianceType}
          setVarianceType={setVarianceType}
          tableFilters={tableFilters}
          setTableFilters={setTableFilters}
        />
      </div>
    );
  }, [accuracySubmissionThreshold, data, currencyCode, error, getMessage, isOffsiteEnabled, locale, loading, scanProgress, selectedToggle, toggles, nikeStoreRoles]);

  const dialogModal = useMemo(() => {
    const content = (
      <ProductCard
        setIsOpen={setIsOpened}
        rowCount={items?.length}
        scanProductDetailData={scanProductDetailData}
        scanType={data?.scanType}
        moveNext={moveNext}
        movePrevious={movePrevious}
        currentProduct={currentProduct}
      />
    );

    return <Dialog id={id} isOpen={isOpened} setIsOpen={setIsOpened} content={content} />;
  }, [isOpened, data?.scanType, scanProductDetailData, items?.length]);

  const ellipses = (
    <div id="scan-summary-ellipses" data-testid="scan-summary-ellipses">
      <CustomSpinner size="10px" color="#5CDAA8" loading margin="25px" />
    </div>
  );

  const tableOptions = useTableOptions(tableFilters, varianceType, items, setScanItems, setSOHLoading, setScanProductDetailData, setIsOpened, setCurrentProduct);

  return (
    <>
      <BaseViewPage
        id={id}
        title={pageTitle}
        storeConfigValue="isRfidScanReportEnabled"
        loading={false}
        error={error}
        details={{
          title: sohLoading ? ellipses : '',
          options: tableOptions,
          columns: getColumns(
            id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, currencyCode, selectedToggle, tableFilters, getMessage,
          ),
          rows: loading ? [] : items,
        }}
        customSummaryContainer={customSummaryContainer}
        dialog={dialogModal}
      />
    </>
  );
};

export default ScanDetailsPage;
