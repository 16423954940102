/* eslint-disable no-param-reassign */
/* eslint-disable no-await-in-loop */
/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import React, { useContext } from 'react';
import writeXlsxFile from 'write-excel-file';
import { cloneDeep } from 'lodash';
import { getRfidTableHeader } from '../../../components/RFIDTableHeader';
import { getLocalizedNumber } from '../utils';
import getLocalizedPercent from '../../../utils/getLocalizedPercent';
import getFormattedPrice, { formatCurrency } from '../../../utils/getFormattedPrice';
import { SimDateTime } from '../../../utils/datetime';
import { fetchStoreStockData } from '../../../context/cpa/cpa.axios';
import { getSegmentedSOH } from '../../../utils/getStockOnHand';

import { SimWebContext } from '../../../context/SimWeb.provider';

/**
 * A helper function to get the columns for the table
 * @param {string} id - the report id
 * @param {boolean} rfidEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteEnabled - is rfid enabled in the store
 * @param {boolean} isOffsiteInactive - is offsite enabled in the store
 * @param {string} locale - the locale of the store
 * @param {string} currencyCode - the currency code of the store
 * @param {string} aggregatedBy - the aggregation type - genderAge or retailCategory
 * @param {array} tableFilters - a list of filters applied to the table
 * @param {function} getMessage - the function to get the localized message
 */
export const getColumns = (
  id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, locale, currencyCode, aggregatedBy, tableFilters, getMessage,
) => {
  const rfidColumn = getRfidTableHeader('rfidSoh', getMessage('rfidStock'), id, rfidEnabled, isOffsiteEnabled, isOffsiteInactive, false, true);
  rfidColumn.options.sort = false;
  rfidColumn.options.filter = false;

  const [categoryFilter, divisionFilter] = tableFilters;

  const columns = [
    {
      name: 'productCode',
      label: getMessage('styleColor'),
    },
    {
      name: 'description',
      label: getMessage('description'),
    },
    {
      name: 'division',
      label: getMessage('division'),
      options: {
        filter: false,
        ...(divisionFilter ? { filterList: [divisionFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'genderAge',
      label: getMessage('genderAge'),
      options: {
        display: 'excluded',
        filter: false,
        ...((categoryFilter && aggregatedBy === 'genderAge') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'retailCategory',
      label: getMessage('retailCategory'),
      options: {
        filter: false,
        ...((categoryFilter && aggregatedBy === 'retailCategory') ? { filterList: [categoryFilter] } : { filterList: [] }),
      },
    },
    {
      name: 'currentPrice',
      label: getMessage('currentPrice'),
      options: {
        customBodyRender: value => (
          <span>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },
    },
    {
      name: 'valueVariance',
      label: getMessage('valueVariance'),
      options: {
        customBodyRender: value => (
          <span className={value !== 0 ? 'scan-total-value-variance-value' : ''}>{getFormattedPrice(value, locale, currencyCode)}</span>
        ),
      },
    },
    {
      name: 'scanned',
      label: getMessage('scanned'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'expected',
      label: getMessage('expected'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'missing',
      label: getMessage('missing'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'extra',
      label: getMessage('extra'),
      options: {
        customBodyRender: value => getLocalizedNumber(locale, value),
      },
    },
    {
      name: 'accuracy',
      label: getMessage('accuracy'),
      options: {
        customBodyRender: value => getLocalizedPercent(locale, value),
      },
    },
    rfidColumn,
    {
      name: 'totalRfidSoh',
      label: 'Total RFID SOH',
      options: {
        sort: false,
        filter: false,
      },
    },
  ];

  return columns;
};

/**
 * Creates headers for excel download
 * @param {*} columns columns to create headers for
 */
export const createDownloadHeaders = (columns) => {
  const headerRow = [];
  columns.map(column => headerRow.push({ value: column?.label?.toUpperCase() ?? '', fontWeight: 'bold' }));
  return headerRow;
};

/**
 * Calculates the maximum width for each column in a 2D array of data.
 *
 * @param {Array<Array<any>>} data - A 2D array where each sub-array represents a row of data.
 * @returns {Array<number>} An array of numbers representing the maximum width of each column.
 */
export const calculateColumnWidths = (data) => {
  const widths = data[0].map((_, colIndex) => Math.max(...data.map(row => (row?.[colIndex]?.value ?? '').toString().length)));
  return widths;
};

export const getTotalSohs = (data) => {
  const offSiteQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.offSiteQuantity || 0)), 0) || 0;
  const salesFloorQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.salesFloorQuantity || 0)), 0) || 0;
  const stockRoomQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.segmentation?.stockRoomQuantity || 0)), 0) || 0;
  const physicalStockQuantity = data?.reduce((acc, curr) => (acc + (+curr.physicalStock?.quantity || 0)), 0) || 0;
  const fiscalStockQuantity = data?.reduce((acc, curr) => (acc + (+curr.fiscalStock?.quantity || 0)), 0) || 0;

  return {
    fiscalStock: {
      quantity: fiscalStockQuantity,
    },
    physicalStock: {
      quantity: physicalStockQuantity,
      segmentation: {
        offSiteQuantity,
        salesFloorQuantity,
        stockRoomQuantity,
      },
    },
  };
};

export const mergeIventoryData = (inventory, stock, isOffsiteEnabled) => {
  const mapping = {
    fullStore: 'fullStore',
    salesFloor: 'salesFloor',
    stockRoom: 'stockRoom',
    offsite: 'offSite',
  };
  const newItem = cloneDeep(inventory);
  Object.keys(newItem).map(key => {
    newItem?.[key]?.map(gtinItem => {
      const gtinStockData = stock?.find(stockItem => stockItem.gtin === gtinItem.gtin);
      let quantity;
      if (key === 'fullStore') {
        quantity = getSegmentedSOH(gtinStockData?.physicalStock?.segmentation, isOffsiteEnabled) || '-';
      } else {
        quantity = gtinStockData?.physicalStock?.segmentation?.[`${mapping[key]}Quantity`] || 0;
      }
      // eslint-disable-next-line no-param-reassign
      gtinItem.quantity = quantity;
    });
  });

  return newItem;
};

/**
 * Adds Stock on Hand (SOH) information to the provided items.
 *
 * @param {string} storeId - The ID of the store.
 * @param {Array} items - The list of items to process.
 * @param {Array} currentPageData - The list of items currently displayed.
 * @param {boolean} isOffsiteEnabled - Flag indicating if offsite is enabled.
* @returns {Promise<void>} - A promise that resolves when the operation is complete.
 */
export const getUpdatedSOHData = async (storeId, items, currentPageData, isOffsiteEnabled) => {
  const processItems = async (items, storeStockData) => {
    const newItems = cloneDeep(items);

    currentPageData.forEach(row => {
      const currentItem = newItems.find(item => item.productCode === row[0]);
      const stock = storeStockData?.filter(stockItem => stockItem.productCode === currentItem.productCode);
      if (currentItem && stock.length > 0) {
        const totalSohs = getTotalSohs(stock);
        currentItem.rfidSoh = getSegmentedSOH(totalSohs.physicalStock.segmentation, isOffsiteEnabled);
        currentItem.totalRfidSoh = totalSohs.physicalStock.quantity || 0;
        // ask Matthew if this is needed?
        // currentItem.inventory = mergeIventoryData(currentItem.inventory, stock, isOffsiteEnabled);
      }
    });

    return newItems;
  };

  try {
    const productCodes = currentPageData.map(item => item?.[0] || '');
    const storeStockData = await fetchStoreStockData(storeId, productCodes, true);
    const filterItems = await processItems(items, storeStockData);
    return filterItems;
  } catch (error) {
    throw error;
  }
};

export const onRowClick = (rowData, items, setScanProductDetailData, setIsOpened, setCurrentProduct) => {
  const productCode = rowData?.[0];
  if (!productCode) return;

  if (!items?.length) return;

  const productDetails = items?.find(item => item.productCode === productCode);
  setScanProductDetailData(productDetails);
  setCurrentProduct(productCode);
  setIsOpened(true);
};

export const onTableChange = async (action, tableState, storeId, items, setSOHLoading, setScanItems, isOffsiteEnabled) => {
  const {
    page, rowsPerPage, displayData,
  } = tableState;

  // should pull 2 pages worth of data
  const numberOfRowsToPull = rowsPerPage * 2;
  const startIndex = page * numberOfRowsToPull;
  const endIndex = ((page + 1) * (numberOfRowsToPull)) - 1;

  // used to determine if something has changed. using updateProps will cause an infinite loop
  const hasDisplayedDataChanged = displayData.length !== parseInt(sessionStorage.getItem('displayedRowCount'), 10);
  const movedToPreviousPage = page < parseInt(sessionStorage.getItem('page'), 10);

  if (movedToPreviousPage) return;
  if (hasDisplayedDataChanged || action === 'search' || action === 'sort' || action === 'changePage' || action === 'changeRowsPerPage') {
    setSOHLoading(true);

    const currentPage = displayData.slice(startIndex, endIndex);
    const currentPageData = currentPage.map(row => row.data);

    // Extract dataIndexes for product card navigation
    const allDataIndexes = currentPage.map(row => ({ index: row.index, productCode: row.data[0] }));
    sessionStorage.setItem('allDataIndexes', JSON.stringify(allDataIndexes));

    await getUpdatedSOHData(storeId, items, currentPageData, isOffsiteEnabled)
      .then(updatedItems => {
        setScanItems(updatedItems);
      })
      .catch(error => {
        // eslint-disable-next-line no-console
        console.error('Error updating SOH:', error);
      })
      .finally(() => {
        sessionStorage.setItem('displayedRowCount', tableState.displayData.length);
        sessionStorage.setItem('page', page);
        setSOHLoading(false);
      });
  }
};

export const onDownload = (_buildHead, _buildBody, newColumns, newItems, locale, currencyCode) => {
  try {
    const valueVarianceIndex = newColumns.findIndex(column => column.name === 'valueVariance');
    const currentPriceIndex = newColumns.findIndex(column => column.name === 'currentPrice');

    newItems.sort((a, b) => {
      const valueA = parseFloat(a.data[valueVarianceIndex]) || 0;
      const valueB = parseFloat(b.data[valueVarianceIndex]) || 0;
      return valueB - valueA;
    });

    const dataRows = newItems.map(row => row.data.map((value, index) => {
      let currentValue = value;

      if (index === valueVarianceIndex || index === currentPriceIndex) {
        currentValue = formatCurrency(value ? parseFloat(value) : 0, locale, currencyCode);
      }

      return { value: currentValue?.toString() ?? '', type: String };
    }));

    const headerRow = createDownloadHeaders(newColumns);
    const columnWidths = calculateColumnWidths([...[headerRow], ...dataRows]);
    const columns = columnWidths.map(width => ({ width: width + 2 }));
    writeXlsxFile([headerRow, ...dataRows], { columns, fileName: `ScanReport-${SimDateTime.toUtcISO()}.xlsx` });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }

  return false; // cancels default datatable download logic
};

/**
 * Custom hook to generate table options for a data table.
 *
 * @param {Array} tableFilters - Array containing category and division filters.
 * @param {string} varianceType - Type of variance ('missing' or 'extra').
 * @param {Array} items - Array of items to be displayed in the table.
 * @param {Function} setScanItems - Function to set scan items.
 * @param {Function} setSOHLoading - Function to set the state of SOH loading.
 * @param {Function} setScanProductDetailData - Function to set scan product detail data.
 * @param {Function} setIsOpened - Function to set the state of whether a modal or detail view is opened.
 * @returns {Object} Table options for configuring the data table.
 */
const useTableOptions = (tableFilters, varianceType, items, setScanItems, setSOHLoading, setScanProductDetailData, setIsOpened, setCurrentProduct) => {
  const {
    currencyCode, getMessage, isOffsiteEnabled, isPrintingEnabled, locale, storeId,
  } = useContext(SimWebContext);

  const [categoryFilter, divisionFilter] = tableFilters;

  return {
    selectableRows: 'none',
    print: isPrintingEnabled,
    responsive: 'standard',
    rowsPerPage: 25,
    rowsPerPageOptions: [10, 25, 50, 100],
    filter: true,
    download: true,
    onRowClick: (rowData) => onRowClick(rowData, items, setScanProductDetailData, setIsOpened, setCurrentProduct),
    onDownload: (_buildHead, _buildBody, newColumns, newItems) => onDownload(_buildHead, _buildBody, newColumns, newItems, locale, currencyCode),
    onTableChange: (action, tableState) => onTableChange(action, tableState, storeId, items, setSOHLoading, setScanItems, isOffsiteEnabled),
    downloadOptions: {
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    textLabels: {
      body: {
        noMatch: getMessage('noData'),
      },
    },
    sortOrder: {
      ...((!categoryFilter && !divisionFilter) && { name: 'valueVariance', direction: 'desc' }),
      ...(((categoryFilter || divisionFilter) && varianceType === 'missing') && { name: 'missing', direction: 'desc' }),
      ...(((categoryFilter || divisionFilter) && varianceType === 'extra') && { name: 'extra', direction: 'desc' }),
    },
    fixedHeader: true,
    tableBodyMaxHeight: '625px',
  };
};

export default useTableOptions;
