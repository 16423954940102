/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
import React, { useContext, useMemo } from 'react';
import {
  array, bool, func, object, shape, string, oneOfType, number,
} from 'prop-types';
import { getDatePicker, getPageContent, getReportDetails, getTutorial } from './BaseViewPageUtils';
import TabPanel from '../../components/TabPanel';
import { tutorialContext } from '../../context/Tutorial.provider';
import { SimWebContext } from '../../context/SimWeb.provider';

import './BaseViewWithFacets.css';

const BaseFacetsViewPage = ({
  id, title, loading, datepicker, visuals, facets, pageContent, details, error, dialog, tabs, searchToggle, tabValue, reportInfo,
}) => {
  const { getMessage, isReady } = useContext(SimWebContext);
  const {
    stepIndex, setStepIndex, startTutorial, handleStartTutorial, isTutorialEnabled,
  } = useContext(tutorialContext);
  const datepickerComponent = useMemo(() => getDatePicker(id, loading, datepicker), [datepicker, loading]);
  const reportDetails = useMemo(() => (getReportDetails(id, details, getMessage, dialog)), [details]);
  const pageContents = useMemo(() => pageContent || getPageContent(id, loading, error, reportDetails), [loading, error, reportDetails]);
  const tutorialToggle = useMemo(() => getTutorial(details, handleStartTutorial, startTutorial, setStepIndex, stepIndex, loading),
    [details, stepIndex, setStepIndex, isTutorialEnabled, startTutorial, handleStartTutorial, loading]);

  const detailsContent = useMemo(() => {
    const content = (
      <div className="content-details">
        <div className="facets">
          {reportInfo}
          {searchToggle}
          {facets}
        </div>
        <div>
          {visuals}
          {pageContents}
        </div>
      </div>
    );

    return tabs ? (<TabPanel value={tabValue} index={tabValue}>{content}</TabPanel>) : content;
  }, [searchToggle, facets, visuals, tabs, pageContent, tabValue]);

  return isReady ? (
    <div data-testid={id} className="report">
      <div>
        <div className="title-block flex-row">
          <div className="feature-title">
            {getMessage(title)}
          </div>
          {datepickerComponent}
        </div>
      </div>
      {tutorialToggle}
      <div>
        {tabs}
        {detailsContent}
      </div>
    </div>
  ) : null;
};

BaseFacetsViewPage.propTypes = {
  id: string.isRequired,
  title: string.isRequired,
  loading: bool,
  error: object,
  facets: object,
  visuals: shape({
    chart: object,
    legend: object,
  }),
  reportInfo: object,
  pageContent: object,
  details: shape({
    title: oneOfType([
      string,
      object,
    ]),
    options: object,
    columns: array,
    theme: object,
  }),
  datepicker: shape({
    startDate: oneOfType([
      string,
      object,
    ]),
    endDate: oneOfType([
      string,
      object,
    ]),
    handleDateChange: func,
  }),
  dialog: object,
  tabs: object,
  tabValue: number,
  searchToggle: object,
};

BaseFacetsViewPage.defaultProps = {
  datepicker: null,
  dialog: null,
  details: null,
  error: null,
  facets: null,
  loading: false,
  pageContent: null,
  reportInfo: null,
  searchToggle: null,
  tabs: null,
  tabValue: null,
  visuals: null,
};

export default BaseFacetsViewPage;
