import React from 'react';
import {
  createTheme, Dialog, ThemeProvider, StyledEngineProvider,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import { LoadingIndicator } from '../../assets/Svgs/index';
import Tutorial from '../../components/tutorial/Tutorial';
import ErrorAlert from '../../components/alerts/Error';
import DateRangeWithOptions from '../../components/date-range/DateRangeWithOptions';

export const getDatePicker = (id, loading, datepicker) => {
  if (datepicker) {
    const {
      startDate, endDate, handleDateChange, onGoClick, isOutsideRange, tooManyDaysSelected, maxSearchableDays,
    } = datepicker;
    return (
      <div className="date-picker-block" data-testid={`${id}-datepicker`}>
        <DateRangeWithOptions
          optionsLabel="View"
          start={startDate}
          end={endDate}
          onClick={onGoClick}
          onDateChange={handleDateChange}
          isOutsideRange={isOutsideRange}
          disabled={loading}
          tooManyDaysSelected={tooManyDaysSelected}
          maxSearchableDays={maxSearchableDays}
        />
      </div>
    );
  }
  return null;
};

/**
 * use as a dialog container for modal pops with contect, such as Product Cards
 * to the BaseViewPage
 * @param {string} id page id
 * @param {object} content dialog modal content, such as a product card
 * @param {boolean} isOpen variable to deterimine if the modal is visibile
 * @param {func} setIsOpen function to set state of the isOpen variable
 */
export const baseDialogWrapper = ({
  id, content, isOpen, setIsOpen,
}) => (
  <div data-testid={`${id}-dialog-container`}>
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      data-testid={`${id}-dialog`}
      PaperProps={{
        style: {
          minHeight: '65%',
          maxHeight: '65%',
          minWidth: '80%',
          maxWidth: '80%',
        },
      }}
    >
      {content}
    </Dialog>
  </div>
);

/**
 * use as the default MuiTableTeme, this object can be extended and then passed in
 * to the BaseViewPage
 */
export const baseMuiTheme = {
  components: {
    MuiTableRow: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          height: '10px',
          width: '10px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '16px 10px',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        fixedHeader: {
          padding: '10px',
        },
      },
    },
  },
};

/**
 * use as the default MuiTableOptions, this object can be extended and then passed in
 * to the BaseViewPage
 * @param {function} getMessage translations helper func
 */
export const baseDetailsTableOptions = (getMessage) => ({
  selectableRows: 'none',
  selectableRowsHeader: false,
  rowsPerPage: 100,
  rowsPerPageOptions: [15, 25, 50, 100, 1000],
  print: true,
  responsive: 'standard',
  download: false,
  filter: true,
  textLabels: {
    body: {
      noMatch: getMessage('noData'),
    },
  },
  sortOrder: {
    name: 'productCode',
    direction: 'desc',
  },
  fixedHeader: true,
  tableBodyHeight: '625px',
});

export const getReportDetails = (id, details, getMessage, dialog) => {
  const {
    options: tableOptions, title, rows, columns, theme,
  } = { ...details };
  const options = tableOptions ?? baseDetailsTableOptions(getMessage);
  const theming = theme ?? createTheme(baseMuiTheme);
  if (columns && Array.isArray(rows)) {
    return (
      <div data-testid={`${id}-table`}>
        <StyledEngineProvider injectFirst>
          <ThemeProvider theme={theming}>
            <MUIDataTable
              title={title}
              data={rows}
              columns={columns}
              options={options}
            />
          </ThemeProvider>
        </StyledEngineProvider>
        {dialog}
      </div>
    );
  }

  return null;
};

export const getPageContent = (id, loading, error, reportDetails, tabs) => {
  if (loading) {
    return (
      <LoadingIndicator
        dataTestId={`${id}-loading`}
        extraClass="loading-indicator"
      />
    );
  }

  return (
    <>
      {error ? <ErrorAlert errorObject={error} apiName={id} pageCode="" /> : null}
      {tabs}
      {reportDetails}
    </>
  );
};

export const getTutorial = (details, handleStartTutorial, startTutorial, setStepIndex, stepIndex, loading) => (
  <Tutorial
    setTutorialState={handleStartTutorial}
    startTutorial={startTutorial}
    setStepIndex={setStepIndex}
    stepIndex={stepIndex}
    disabled={!details?.rows?.length || loading}
  />
);
